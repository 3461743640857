import React, {useState} from 'react';
import {Loader} from './Common'
import {getDashboardDetails, getDataFromUrl, requestList} from "../services/server";
import cons, {serverApi} from "../helper/Consts";
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import {statisticsIcon} from "../Assets";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ExportToExcel from "./Common/ExportToExcel";
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import ReactEcharts from "./ReactECharts";
import Checkbox from '@material-ui/core/Checkbox';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {getCookie} from "../helper/Cookies";

const CellStatsPopup = (props) => {
    const {closePopup, dataResponse, loading, dashboardData, cellData, showAqua} = props;
    const [activeItemIndex, setActiveItemIndex] = useState(props.activeItemIndex);

    const data = dataResponse;
    const [cellsData, setcellsData] = useState(cellData ? cellData : []);

    const [sitesData, setSitesData] = useState(props.sitesData ? props.sitesData : []);
    const [sitesId, setSitesId] = useState(props.siteId ? props.siteId : null);
    const [deviceId, setDeviceId] = useState(props.device ? props.device : null);
    const [deviceData, setDeviceData] = useState(props.deviceData ? props.deviceData : []);
    const [isCell, setIsCell] = useState(dashboardData[activeItemIndex].title === "Bank Info" || dashboardData[activeItemIndex].title === "Bank Status" ? false : true);
    const [param, setParam] = useState(null);
    const [paramName, setParamName] = useState(null);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [noOfRows, setNoOfRows] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [pageSizeOptions, setPageSizeOptions] = useState([10, 20, 50, 100, 200, 300, 500, 1000]);
    const [isGraph, setIsGraph] = useState(false);

    const [selectedCell, setSelectedCell] = useState([]);
    /*
        const [paramsData, setParamData] = useState(dashboardData[activeItemIndex].data);
    */
    const [value, setValue] = useState(['All Cells']);

    const handleChange = (event) => {
        //this.setState({value: event.option});
        setValue(Array.from(event.target.selectedOptions, (item) => item.value))
    }
    var paramsData = [];
    if (dashboardData) {
        dashboardData.forEach(prop => {
            if (prop.title === "Bank Info" && !showAqua) {
                paramsData = prop.data;
            }
        })
    }

    /*   if(data)
           data.forEach(prop=>{
           tableData.push( {id:prop.id+"",ctitle:prop.ctitle,cellStatus:prop.cellStatus,comStatus:prop.comStatus,value1:prop.value1,value2:prop.value2})
       })*/
    var data1 = [];

    var columns = [];
    if (!showAqua) {
        if (isCell) {
            columns = [
                {
                    Header: 'Date',
                    accessor: 'dateTime',
                },
                {
                    Header: 'Name',
                    accessor: 'cellName',
                },
                {
                    Header: 'Voltage',
                    accessor: 'voltageValue',
                },
                {
                    Header: 'Temperature',
                    accessor: 'temperatureValue',
                },
                {
                    Header: 'Communication Status',
                    accessor: 'commStatus',
                },
                {
                    Header: 'Temperature Status',
                    accessor: 'temperature',
                },
                {
                    Header: 'Voltage Status',
                    accessor: 'voltage',
                }
            ]

            if(param!==null){
                if(param===0){
                    columns = [
                        {
                            Header: 'Date',
                            accessor: 'dateTime',
                        },
                        {
                            Header: 'Name',
                            accessor: 'cellName',
                        },
                        {
                            Header: 'Voltage',
                            accessor: 'voltageValue',
                        },
                        {
                            Header: 'Communication Status',
                            accessor: 'commStatus',
                        },
                        {
                            Header: 'Voltage Status',
                            accessor: 'voltage',
                        }
                    ]

                }else {
                    columns = [
                        {
                            Header: 'Date',
                            accessor: 'dateTime',
                        },
                        {
                            Header: 'Name',
                            accessor: 'cellName',
                        },
                        {
                            Header: 'Temperature',
                            accessor: 'temperatureValue',
                        },
                        {
                            Header: 'Communication Status',
                            accessor: 'commStatus',
                        },
                        {
                            Header: 'Temperature Status',
                            accessor: 'temperature',
                        }
                    ]

                }
            }
        } else {
            columns = [
                {
                    Header: 'Sr.no',
                    accessor: 'id',
                },
                {
                    Header: 'Date',
                    accessor: 'dateTime',
                },
                {
                    Header: 'String Voltage',
                    accessor: 'stringVoltage',
                },
                {
                   Header:'Current' ,
                    accessor:'instantaneousCurrent'
                },
                {
                    Header: 'Current Status',
                    accessor: 'current',
                },
                {
                    Header: 'State Of Charge(SOC)',
                    accessor: 'stateOfCharge_SOC',
                },
                {
                    Header: 'Depth Of Discharge(DOD)',
                    accessor: 'depthOfDischarge_DOD',
                },
                {
                    Header: 'Ambient Temperature',
                    accessor: 'ambientTemperature',
                },
                {
                    Header: 'Charge-Discharge Cycles',
                    accessor: 'chargeDischargeCycles',
                },
                {
                    Header: 'Ampere Hour In',
                    accessor: 'ampereHourIn',
                },
                {
                    Header: 'Ampere Hour Out',
                    accessor: 'ampereHourOut',
                },
                {
                    Header: 'Charging Energy',
                    accessor: 'chargingEnergy',
                },
                {
                    Header: 'Discharging Energy',
                    accessor: 'dischargingEnergy',
                },
                {
                    Header: 'Battery Run Hours',
                    accessor: 'batteryRunHours',
                },
                {
                    Header: 'Peak Charge Current',
                    accessor: 'peakChargeCurrent',
                },
                {
                    Header: 'Average Charge Current',
                    accessor: 'averageChargeCurrent',
                },
                {
                    Header: 'Charge Time',
                    accessor: 'chargeTime',
                },
                {
                    Header: 'Discharge Time',
                    accessor: 'dischargeTime',
                },
                {
                    Header: 'Hourly Avg Temp',
                    accessor: 'hourlyAvgTemp',
                },
                {
                    Header: 'Cumulative Avg Temp',
                    accessor: 'cummulativeAvgTemp',
                },
                {
                    Header: 'Peak Discharge Current',
                    accessor: 'peakDischargeCurrent',
                },
                {
                    Header: 'Average Discharge Current',
                    accessor: 'averageDischargeCurrent',
                }
            ]

            if (param !== null) {

                var columns1 = [
                    {
                        Header: 'Date',
                        accessor: 'date',
                    },
                    {
                        Header: 'Time',
                        accessor: 'time',
                    }
                ];
                columns.forEach((prop, i) => {
                    console.log("ppp", i + 1, param);
                    if (prop.Header === paramName)
                        columns1.push(prop);
                });
                columns = columns1;
            }
        }

    } else {
        columns = [
            {
                Header: 'Date Time',
                accessor: 'dataTime',
            },
            {
                Header: 'EB',
                accessor: 'ebOnOFF',
            },
            {
                Header: 'DG',
                accessor: 'dgOnOFF',
            },
            {
                Header: 'PH Value',
                accessor: 'phValue',
            },
            {
                Header: 'PH Status',
                accessor: 'phStatus',
            },
            {
                Header: 'Dissolve Oxygen',
                accessor: 'dovalue',
            },
            {
                Header: 'Dissolve Oxygen Status',
                accessor: 'doStatus',
            },
            {
                Header: 'Salinity',
                accessor: 'conductivity',
            },
            {
                Header: 'Salinity Status',
                accessor: 'salinityStatus',
            },
            {
                Header: 'Temperature',
                accessor: 'temperature',
            },
            {
                Header: 'Temperature Status',
                accessor: 'tempStatus',
            },
            {
                Header: 'Ammonia',
                accessor: 'ammonia',
            },
            {
                Header: 'Ammonia Status',
                accessor: 'ammoniaStatus',
            },
            {
                Header: 'Fuel Fill',
                accessor: 'fuelFill',
            },
            {
                Header: 'Fuel Theft',
                accessor: 'fuelTheft',
            },
            {
                Header: 'Fuel Refill',
                accessor: 'fuelRefill',
            }
        ];
        tableData.forEach((prop, index) => {
            if (index === 0) {
                if (prop.aretorData) {
                    Object.keys(prop.aretorData).forEach((o, i) => {

                        columns.push({
                            Header: 'Aerator Status ' + o,
                            accessor: '' + o,
                        })

                    })
                }

            }

        });
    }

    const handleDeviceData = (resp) => {
        if (resp) {
            setDeviceData(resp);
            if (resp.length > 0){
                setDeviceId(resp[0]);
                console.log("sitesId",sitesId.id);
            }

        }
    };
    const handleSiteDashboard = (resp) => {
        console.log("sss", resp.data.getDashboard);
        if( resp.data.getDashboard){
            resp.data.getDashboard.forEach(prop=>{
                if(prop.title==='Cell Info'){
                    if(prop.data){
                        prop.data.forEach(record=>{
                            if (record.title === "Total Cells") {
                                setcellsData( record.cellDetails);
                            }
                        })
                    }
                }
            })
        }
    };

    const getData = (pageNo, rows) => {
        if (showAqua) {
            var data = {
                "siteId": sitesId.id,
                "deviceId": "",
                "fromDate": fromDate !== "" ? fromDate + " 00:00" : "",
                "toDate": toDate !== "" ? toDate + " 24:00" : "",
                "page": pageNo,
                "rowsCount": rows
            };
            setIsLoading(true);
            requestList(serverApi.GET_STATASTICS_AQUA, data, handleAquaStats)
        } else {
            getDataFromUrl(serverApi.GET_SINGLE_SITE_DATA + sitesId.id + "/" + deviceId.serialNo, handleSiteDashboard);

            var data = {
                "siteId": sitesId.id,
                "deviceId": deviceId.serialNo,
                "cellOrBattery": !isCell ? "battery" : "cell",
                "fromDate": fromDate !== "" ? fromDate + " 00:00" : "",
                "toDate": toDate !== "" ? toDate + " 24:00" : "",
                "page": pageNo,
                "rowsCount": rows
            };
            setIsLoading(true);
            requestList(serverApi.GET_STATASTICS, data, handleStats)
        }

    };
    const handleAquaStats = (resp) => {
        if (resp)
            setTotalPages(resp.totalPages);
        var dta1 = resp.tableData.map((prop, index) => {
            var obj = {...prop}
            if (prop.aretorData) {
                Object.keys(prop.aretorData).forEach((o, i) => {
                    if (index === 0) {
                        columns.push({
                            Header: 'Aerator Status ' + o,
                            accessor: '' + o,
                        })
                    }
                    obj = {...obj, [o]: prop.aretorData[o]}
                })
            }

            console.log("obj..", obj);
            return obj;
        });

        setTableData(dta1);
        setIsLoading(false);
        //   setNoOfRows(20);
    };


    const handleStats = (resp) => {
        console.log("rep...",isCell, resp);
        if (resp)
            setTotalPages(resp.totalPages);

        if (isCell) {
            if (resp.cellRes) {
                var data = [];
                var sizes = [];
                resp.cellRes.forEach((prop, i) => {
                    if (selectedCell.length > 0) {
                        prop.cells.forEach(o => {
                            selectedCell.forEach(p => {
                                if (o.cellName === p.value) {
                                    var obj = {...o, dateTime: o.date + " " + o.time}
                                    data.push(obj)
                                }

                            })

                        });
                        setNoOfRows(20);

                    } else {
                        setNoOfRows(prop.cells.length);
                        sizes.push((i + 1) * prop.cells.length);
                        var data1 = [];
                        prop.cells.forEach(prop => {
                            var obj = {...prop, dateTime: prop.date + " " + prop.time}
                            data1.push(obj)
                        });
                        data.push(...data1);
                    }
                })
                if (selectedCell.length === 0)
                    setPageSizeOptions(sizes)
                else
                    setPageSizeOptions([10, 20, 50, 100, 200, 300, 500, 1000])


            }
            setTableData(data);
            setIsLoading(false)

        } else {

            setPageSizeOptions([10, 20, 50, 100, 200, 300, 500, 1000]);
            var data1 = [];
            resp.batteryRes.forEach((prop, i) => {
                var no=i+1;
                var obj = {...prop, dateTime: prop.date + " " + prop.time, id: no+""}
                data1.push(obj)
            });
            setTableData(data1);
            setIsLoading(false);

        }
    };
    var xAxis = [], yAxis = [], yAxis1 = [], yAxis2 = [], yAxis3 = [], yAxis4 = [], xAxisName = "Date Time", yAxisName = "",
        yAxisName1 = "", yAxisName2 = "", yAxisName3 = "", yAxisName4 = "";
    var excelData = [],seriesData=[];

    excelData = tableData.map(prop => {
        if (!showAqua) {
            xAxis.push(prop.date + " " + prop.time);
            if (isCell) {
                if (param === 0) {
                    var val = prop.voltageValue.split(" ");
                    yAxis.push(val[0]);
                    yAxisName = val[1];
                } else if(param === 1){
                    var val = prop.temperatureValue.replace('°C', '');
                    yAxis.push(val);
                    yAxisName = '°C';
                }else {
                    var val = prop.voltageValue.split(" ");
                    yAxis.push(val[0]);
                    yAxisName = val[1];
                    var val1 = prop.temperatureValue.replace('°C', '');
                    yAxis1.push(val1);
                    yAxisName1 = '°C';
                }

            } else {
                if (columns[2]) {
                    var val = prop[columns[2].accessor].split(" ");
                    yAxis.push(val[0]);
                    yAxisName = val[1];
                }

            }
            return {site: sitesId.name, device: deviceId.id, ...prop}

        } else {
            paramsData = ["All Params","PH Value", "Dissolve Oxygen", "Salinity", "Temperature", "Ammonia"];
           /* if (paramName === null) {
                yAxis.push(prop.phValue);
            } else {*/
            if (paramName !== null&&paramName !== "All Params"){
                columns.forEach((o) => {
                    var val = prop[o.accessor];
                    if(o.Header === paramName){
                            if(paramName==="PH Value")
                                yAxis.push(val);
                            else {
                                val = prop[o.accessor].split(" ");
                                yAxisName = val[1];
                                yAxis.push(val[0]);
                            }


                    }

                })

            }else {
                paramsData.forEach((k,i)=>{
                    columns.forEach((o) => {
                        var val = prop[o.accessor];
                        if(o.Header === k){

                            if(i===1)
                                yAxis.push(val);
                            else {
                                val = prop[o.accessor].split(" ");
                                if(i===2){
                                    yAxisName1 = val[1];
                                    yAxis1.push(val[0]);
                                }

                                if(i===3){
                                    yAxisName2 = val[1];
                                    yAxis2.push(val[0]);

                                }
                                if(i===4){
                                    yAxisName3= val[1];
                                    yAxis3.push(val[0]);
                                }

                                if(i===5){
                                    yAxisName4 = val[1];
                                    yAxis4.push(val[0]);
                                }

                            }


                        }

                        /* if (o.Header === paramName) {

                             if(paramName==="PH Value"){
                                 yAxis.push(val);
                                 yAxisName = "Value";

                             }else {
                                 var val = prop[o.accessor].split(" ");
                                 yAxis.push(val[0]);
                                 yAxisName = val[1];
                             }
                         }*/
                    })

                })

            }
          //  }

            console.log("llll", yAxis);
            xAxis.push(prop.dataTime);

            return {site: sitesId.name, ...prop}
        }

    });
    const options = [];
    cellsData.forEach(prop => {
        options.push({label: prop.ctitle, value: prop.ctitle})
    })
    var userData = JSON.parse(getCookie(cons.UDATA));
    var userId = userData.id;
    return (
        <div className="popupBlockContainer">
            <div className="popupBlockMain">
                <div className="popupBlock">

                    <div className="popupHeader">
                        <select className="select_stats" placeholder={"Select Site"}
                                value={sitesId !== null ? sitesId.name : ""} onChange={(event) => {
                            console.log("select", event.target.value);
                            sitesData.forEach(o => {
                                if (o.name + "" === event.target.value) {
                                    setSitesId(o);

                                    if(!showAqua)
                                    getDataFromUrl(serverApi.GET_DEVICES_BY_SITE_ID + o.id+"&userId="+userId, handleDeviceData);

                                }
                            });
                        }}>
                            {sitesData.length > 0 ? sitesData.map(prop => {
                                return (<option>{prop.name}</option>)
                            }) : null}
                        </select>

                        {!showAqua ?
                            <select className="select_stats" placeholder={"Select Device"} onChange={(event) => {
                                deviceData.forEach(o => {
                                    if (o.serialNo + "" === event.target.value) {
                                        setDeviceId(o)

                                    }
                                });
                            }}>
                                {deviceData.length > 0 ? deviceData.map(prop => {
                                    return (<option>{prop.serialNo}</option>)
                                }) : null}

                            </select> : null}


                        {!showAqua ? <select className="select_stats" value={dashboardData[activeItemIndex].title}
                                             onChange={(event) => {
                                                 if (event.target.value === 'Bank Info' || event.target.value === 'Bank Status') {
                                                     setIsCell(false);
                                                      setNoOfRows(20)
                                                 } else {
                                                     setIsCell(true);
                                                     setNoOfRows(20);
                                                 }
                                                 dashboardData.forEach((prop, i) => {

                                                     if (prop.title === event.target.value) {
                                                         setActiveItemIndex(i);
                                                         //  setParamData(prop.data);
                                                     }
                                                 });
                                                 //setIsCell(!isCell);
                                                 setIsGraph(false)
                                                 setTableData([]);
                                                 setParam(null);
                                                 setSelectedCell([]);
                                                 setPageNo(1);

                                             }}>
                            {dashboardData ? dashboardData.map(prop => {
                                if(prop.title!=="Mfg. Details")
                                return <option>{prop.title}</option>
                            }) : null}
                        </select> : null}
                        {isCell && !showAqua ?
                            <div className="select_stats"
                                 style={{paddingTop: "8px", paddingBottom: "10px", height: "20px"}}>
                                <ReactMultiSelectCheckboxes theme={(theme) => ({
                                    ...theme,
                                    borderRadius: '4px',
                                    height: "20px",
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#4db488',
                                        primary: '#4db488',
                                        primary50: "#4db488"
                                    },
                                })} value={selectedCell.length > 0 ? selectedCell : [{
                                    label: "All Cells",
                                    value: "All Cells"
                                }]} placeholderButtonLabel={"Select Cell"} onChange={(event) => {
                                    console.log("dd", event);
                                    setSelectedCell(event)
                                }} options={options}/>


                            </div>
                            : null}

                        {isCell && !showAqua ? <select className="select_stats" onChange={(event) => {
                            if (event.target.value === "All Params") {
                                setParam(null);

                                setParamName(event.target.value)

                            } else {
                                setParamName(event.target.value)
                                setParam(event.target.value === "Voltage" ? 0 : 1)

                            }
                        }}>
                            <option>All Params</option>

                            <option>Voltage</option>
                            <option>Temperature</option>
                        </select> : null}
                        {!isCell && !showAqua ?
                            <select className="select_stats" onChange={(event) => {
                                setParamName(event.target.value)

                                if (event.target.value === "All Params")
                                    setParam(null);
                                else {
                                    paramsData.forEach((prop, i) => {
                                        if (prop.title === event.target.value)
                                            setParam(i)

                                    });
                                }
                            }}>
                                <option>All Params</option>
                                {paramsData.map(prop => {
                                    return <option>{prop.title}</option>
                                })}
                            </select> : null}
                        {showAqua && isGraph ?
                            <select className="select_stats" placeholder={"Select Site"} onChange={(event) => {
                                setParamName(event.target.value)
                            }}>
                                {paramsData.length > 0 ? paramsData.map(prop => {
                                    return (<option>{prop}</option>)
                                }) : null}
                            </select> : null
                        }


                        From Date :
                        <DayPickerInput
                            dayPickerProps={{
                                month: new Date(),
                                showWeekNumbers: true,
                                todayButton: 'Today',
                            }}
                            //  placeholder={'DD-MM-YYYY'}
                            //  formatDate={fromDate}

                            format={'YYYY-MM-DD'}
                            onDayChange={(dateObj) => {
                                setFromDate(moment(dateObj).format("YYYY-MM-DD"));
                                //  console.log("ddd",moment(dateObj).format("YYYY-MM-DD"))
                            }}
                            style={{marginTop: "-5px"}}
                        />
                        To Date :
                        <DayPickerInput
                            format={'YYYY-MM-DD'}
                            onDayChange={(dateObj) => {
                                setToDate(moment(dateObj).format("YYYY-MM-DD"));
                                //  console.log("ddd",moment(dateObj).format("YYYY-MM-DD"))
                            }}
                            dayPickerProps={{
                                month: new Date(),
                                showWeekNumbers: true,
                                todayButton: 'Today',
                            }}
                        />
                        <button style={{width: 80, height: 40, textAlign: "center", padding: 0}} type="submit"
                                onClick={() => {
                                    getData(pageNo, selectedCell.length === 0 && isCell && !showAqua ? 1 : 20);

                                }}>Search
                        </button>
                        <span className="popupClose" onClick={closePopup}><span class="material-icons">
                            close
</span></span>
                    </div>
                    <div className="statspopupMainBlock">
                        {loading ? <Loader/> : <div>
                            {isGraph ? <div>
                                <button style={{
                                    width: "150px",
                                    height: "30px",
                                    float: "left",
                                    padding: 0,
                                    marginLeft: "10px",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    setIsGraph(false)
                                    if(showAqua)
                                        setParamName(null)
                                }}>Back To List
                                </button>
                            </div> : null}
                            {isGraph ?
                                ((paramName===null||paramName==="All Params")?(showAqua?
                                    <div className="statspopupTable" style={{  overflow: "auto"}}>
                                        <div className="statspopupTableAqua">
                                            <ReactEcharts
                                                option={{
                                                    color: ['#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: 'shadow'
                                                        },
                                                        formatter: '{b} <br/>{a} : {c} '
                                                    },
                                                    legend: {
                                                        data: ["PH Value"]
                                                    },
                                                    toolbox: {
                                                        show: true,
                                                        orient: 'vertical',
                                                        left: 'right',
                                                        top: 'center',
                                                        feature: {
                                                            mark: {show: true},
                                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                                            magicType: {
                                                                show: true,
                                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                            },
                                                            restore: {show: true, title: "Restore"},
                                                            saveAsImage: {show: true, title: "Save Image",name:paramName},
                                                        }
                                                    },
                                                    xAxis: [
                                                        {
                                                            type: 'category',
                                                            axisTick: {show: false},
                                                            data: xAxis,
                                                            name: xAxisName
                                                        }
                                                    ],
                                                    yAxis: [
                                                        {
                                                            type: 'value', name: "Value"
                                                        }
                                                    ],
                                                    series: [
                                                        {
                                                            name: "PH Value",
                                                            type: 'bar',
                                                            barGap: 0,
                                                            //  label: labelOption,
                                                            data: yAxis,
                                                            areaStyle:{}
                                                        }
                                                    ]
                                                }}
                                                showLoading={false}
                                                style={{height: '300px', width: '100%'}}/>
                                        </div>

                                        <div className="statspopupTableAqua">
                                            <ReactEcharts
                                                option={{
                                                    color: ['#4db488','#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: 'shadow'
                                                        },
                                                        formatter: '{b} <br/>{a} : {c} '
                                                    },
                                                    legend: {
                                                        data: ["Dissolve Oxygen"]
                                                    },
                                                    toolbox: {
                                                        show: true,
                                                        orient: 'vertical',
                                                        left: 'right',
                                                        top: 'center',
                                                        feature: {
                                                            mark: {show: true},
                                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                                            magicType: {
                                                                show: true,
                                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                            },
                                                            restore: {show: true, title: "Restore"},
                                                            saveAsImage: {show: true, title: "Save Image",name:paramName},
                                                        }
                                                    },
                                                    xAxis: [
                                                        {
                                                            type: 'category',
                                                            axisTick: {show: false},
                                                            data: xAxis,
                                                            name: xAxisName
                                                        }
                                                    ],
                                                    yAxis: [
                                                        {
                                                            type: 'value', name: yAxisName1
                                                        }
                                                    ],
                                                    series: [
                                                        {
                                                            name: "Dissolve Oxygen",
                                                            type: 'bar',
                                                            barGap: 0,
                                                            //  label: labelOption,
                                                            data: yAxis1,
                                                            areaStyle:{}
                                                        }
                                                    ]
                                                }}
                                                showLoading={false}
                                                style={{height: '300px', width: '100%'}}/>
                                        </div>
                                        <div className="statspopupTableAqua">
                                            <ReactEcharts
                                                option={{
                                                    color: ['#003F66','#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: 'shadow'
                                                        },
                                                        formatter: '{b} <br/>{a} : {c} '
                                                    },
                                                    legend: {
                                                        data: ["Salinity"]
                                                    },
                                                    toolbox: {
                                                        show: true,
                                                        orient: 'vertical',
                                                        left: 'right',
                                                        top: 'center',
                                                        feature: {
                                                            mark: {show: true},
                                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                                            magicType: {
                                                                show: true,
                                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                            },
                                                            restore: {show: true, title: "Restore"},
                                                            saveAsImage: {show: true, title: "Save Image",name:paramName},
                                                        }
                                                    },
                                                    xAxis: [
                                                        {
                                                            type: 'category',
                                                            axisTick: {show: false},
                                                            data: xAxis,
                                                            name: xAxisName
                                                        }
                                                    ],
                                                    yAxis: [
                                                        {
                                                            type: 'value', name: yAxisName2
                                                        }
                                                    ],
                                                    series: [
                                                        {
                                                            name: "Salinity",
                                                            type: 'bar',
                                                            barGap: 0,
                                                            //  label: labelOption,
                                                            data: yAxis2,
                                                            areaStyle:{}
                                                        }
                                                    ]
                                                }}
                                                showLoading={false}
                                                style={{height: '300px', width: '100%'}}/>
                                        </div>
                                        <div className="statspopupTableAqua">
                                            <ReactEcharts
                                                option={{
                                                    color: ['#4caf50','#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: 'shadow'
                                                        },
                                                        formatter: '{b} <br/>{a} : {c} '
                                                    },
                                                    legend: {
                                                        data: ["Temperature"]
                                                    },
                                                    toolbox: {
                                                        show: true,
                                                        orient: 'vertical',
                                                        left: 'right',
                                                        top: 'center',
                                                        feature: {
                                                            mark: {show: true},
                                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                                            magicType: {
                                                                show: true,
                                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                            },
                                                            restore: {show: true, title: "Restore"},
                                                            saveAsImage: {show: true, title: "Save Image",name:paramName},
                                                        }
                                                    },
                                                    xAxis: [
                                                        {
                                                            type: 'category',
                                                            axisTick: {show: false},
                                                            data: xAxis,
                                                            name: xAxisName
                                                        }
                                                    ],
                                                    yAxis: [
                                                        {
                                                            type: 'value', name: yAxisName3
                                                        }
                                                    ],
                                                    series: [
                                                        {
                                                            name: "Temperature",
                                                            type: 'bar',
                                                            barGap: 0,
                                                            //  label: labelOption,
                                                            data: yAxis3,
                                                            areaStyle:{}
                                                        }
                                                    ]
                                                }}
                                                showLoading={false}
                                                style={{height: '300px', width: '100%'}}/>
                                        </div>
                                        <div className="statspopupTableAqua">
                                            <ReactEcharts
                                                option={{
                                                    color: ['#fd7e14','#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: 'shadow'
                                                        },
                                                        formatter: '{b} <br/>{a} : {c} '
                                                    },
                                                    legend: {
                                                        data: ["Ammonia"]
                                                    },
                                                    toolbox: {
                                                        show: true,
                                                        orient: 'vertical',
                                                        left: 'right',
                                                        top: 'center',
                                                        feature: {
                                                            mark: {show: true},
                                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                                            magicType: {
                                                                show: true,
                                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                            },
                                                            restore: {show: true, title: "Restore"},
                                                            saveAsImage: {show: true, title: "Save Image",name:paramName},
                                                        }
                                                    },
                                                    xAxis: [
                                                        {
                                                            type: 'category',
                                                            axisTick: {show: false},
                                                            data: xAxis,
                                                            name: xAxisName
                                                        }
                                                    ],
                                                    yAxis: [
                                                        {
                                                            type: 'value', name: yAxisName4
                                                        }
                                                    ],
                                                    series: [
                                                        {
                                                            name: "Ammonia",
                                                            type: 'bar',
                                                            barGap: 0,
                                                            //  label: labelOption,
                                                            data: yAxis4,
                                                            areaStyle:{}
                                                        }
                                                    ]
                                                }}
                                                showLoading={false}
                                                style={{height: '300px', width: '100%'}}/>
                                        </div>

                                    </div>:<div>
                                        <div className="statspopupTable" style={{overflowY:"auto"}}>
                                            <ReactEcharts
                                                option={{
                                                    color: ['#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: 'shadow'
                                                        },
                                                        formatter: '{b} <br/>{a} : {c} ' + yAxisName
                                                    },
                                                    legend: {
                                                        data: [ "Voltage"]
                                                    },
                                                    toolbox: {
                                                        show: true,
                                                        orient: 'vertical',
                                                        left: 'right',
                                                        top: 'center',
                                                        feature: {
                                                            mark: {show: true},
                                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                                            magicType: {
                                                                show: true,
                                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                            },
                                                            restore: {show: true, title: "Restore"},
                                                            saveAsImage: {show: true, title: "Save Image",name: "Voltage"},
                                                        }
                                                    },
                                                    xAxis: [
                                                        {
                                                            type: 'category',
                                                            axisTick: {show: false},
                                                            data: xAxis,
                                                            name: xAxisName
                                                        }
                                                    ],
                                                    yAxis: [
                                                        {
                                                            type: 'value', name: yAxisName
                                                        }
                                                    ],
                                                    series: [
                                                        {
                                                            name: "Voltage",
                                                            type: 'bar',
                                                            barGap: 0,
                                                            //  label: labelOption,
                                                            data: yAxis
                                                        }
                                                    ]
                                                }}
                                                showLoading={false}
                                                style={{height: '500px', width: '100%'}}/>
                                            <ReactEcharts
                                                option={{
                                                    color: ['#003F66', '#94BA33', '#003F66', '#e5323e'],
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: 'shadow'
                                                        },
                                                        formatter: '{b} <br/>{a} : {c} ' + yAxisName1
                                                    },
                                                    legend: {
                                                        data: ["Temperature"]
                                                    },
                                                    toolbox: {
                                                        show: true,
                                                        orient: 'vertical',
                                                        left: 'right',
                                                        top: 'center',
                                                        feature: {
                                                            mark: {show: true},
                                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                                            magicType: {
                                                                show: true,
                                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                            },
                                                            restore: {show: true, title: "Restore"},
                                                            saveAsImage: {show: true, title: "Save Image",name:"Temperature"},
                                                        }
                                                    },
                                                    xAxis: [
                                                        {
                                                            type: 'category',
                                                            axisTick: {show: false},
                                                            data: xAxis,
                                                            name: xAxisName
                                                        }
                                                    ],
                                                    yAxis: [
                                                        {
                                                            type: 'value', name: yAxisName1
                                                        }
                                                    ],
                                                    series: [
                                                        {
                                                            name: "Temperature",
                                                            type: 'bar',
                                                            barGap: 0,
                                                            //  label: labelOption,
                                                            data: yAxis1
                                                        }
                                                    ]
                                                }}
                                                showLoading={false}
                                                style={{height: '500px', width: '100%'}}/>

                                        </div>
                                    </div>)
                                    :
                                <div className="statspopupTable">
                                    <ReactEcharts
                                        option={{
                                            color: ['#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    type: 'shadow'
                                                },
                                                formatter: '{b} <br/>{a} : {c} ' + yAxisName
                                            },
                                            legend: {
                                                data: [paramName]
                                            },
                                            toolbox: {
                                                show: true,
                                                orient: 'vertical',
                                                left: 'right',
                                                top: 'center',
                                                feature: {
                                                    mark: {show: true},
                                                    dataView: {show: false, readOnly: false, title: "Data View"},
                                                    magicType: {
                                                        show: true,
                                                        type: ['line', 'bar', 'stack', 'tiled'],
                                                        title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                                    },
                                                    restore: {show: true, title: "Restore"},
                                                    saveAsImage: {show: true, title: "Save Image",name:paramName},
                                                }
                                            },
                                            xAxis: [
                                                {
                                                    type: 'category',
                                                    axisTick: {show: false},
                                                    data: xAxis,
                                                    name: xAxisName
                                                }
                                            ],
                                            yAxis: [
                                                {
                                                    type: 'value', name: yAxisName
                                                }
                                            ],
                                            series: [
                                                {
                                                    name: paramName,
                                                    type: 'bar',
                                                    barGap: 0,
                                                    //  label: labelOption,
                                                    data: yAxis
                                                }
                                            ]
                                        }}
                                        showLoading={false}
                                        style={{height: '500px', width: '100%'}}/>
                                </div>) :
                                <div className="statspopupTable">
                                    {tableData ?
                                        <ReactTable
                                            loading={isLoading}
                                            data={tableData}
                                            columns={columns}
                                            defaultPageSize={20}
                                            pageSize={noOfRows}
                                            className="-striped -highlight"
                                            showPaginationBottom={false}
                                            showPaginationTop={false}

                                            pageSizeOptions={[10, 20, 50, 100, 200, 500]}
                                            style={{
                                                height: '100%',
                                                margin: 0
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            getPaginationProps={() => {
                                                return {
                                                    style: {width: "80%"}
                                                }
                                            }}
                                            getTheadThProps={() => {
                                                return {
                                                    style: {
                                                        borderRight: null,
                                                        whiteSpace: "pre-wrap",
                                                        backgroundColor: "#009355",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                        //  wordWrap:"break-word",
                                                        fontSize: '14px',
                                                        opacity: 0.7,
                                                        color: "#FFF",
                                                        fontWeight: "bold",
                                                        textAlign: "center"

                                                    }
                                                }
                                            }}
                                            getTheadProps={() => {
                                                return {
                                                    style: {
                                                        borderRight: null,
                                                    }
                                                }
                                            }}
                                            getTdProps={() => {
                                                return {
                                                    style: {
                                                        whiteSpace: "pre-wrap",
                                                        wordWrap: "break-word",
                                                        fontSize: '14px',
                                                        opacity: 0.6,
                                                        paddingTop: "10px",
                                                        paddingBottom: "10px",
                                                        borderRight: null,
                                                        textAlign: "center"
                                                        //  backgroundColor:'green'

                                                    }
                                                }
                                            }}


                                        /> : null}


                                    {tableData && tableData.length > 0 ?
                                        <div style={{width: "80%", height: "50px"}}>
                                            <button
                                                style={{width: "10%", height: "35px", padding: 0, marginRight: "10px"}}
                                                color={"primary"} onClick={() => {
                                                if (totalPages > 0) {
                                                    if (pageNo - 1 > 0) {
                                                        setPageNo(pageNo - 1)
                                                        getData(pageNo - 1, selectedCell.length === 0 && isCell && !showAqua ? 1 : noOfRows);
                                                    }
                                                }
                                            }}>Previous
                                            </button>

                                            <span>Rows</span>


                                            <select value={noOfRows} onChange={(event) => {
                                                setNoOfRows(event.target.value);
                                                getData(pageNo, selectedCell.length === 0 && isCell && !showAqua ? 1 : event.target.value);

                                            }}>
                                                {pageSizeOptions.map(prop => {
                                                    return <option>{prop}</option>
                                                })}
                                            </select>

                                            <span>Page  </span>

                                            <input
                                                id="pageIndex"
                                                type={"number"}
                                                value={pageNo}
                                                disabled={true}
                                            />
                                            <span>Of {totalPages}</span>


                                            <button
                                                style={{width: "10%", height: "35px", padding: 0, marginLeft: "10px"}}
                                                color={"primary"} onClick={() => {
                                                if (totalPages > 0) {
                                                    if (pageNo < totalPages) {
                                                        setPageNo(pageNo + 1)
                                                        getData(pageNo + 1, selectedCell.length === 0 && isCell && !showAqua ? 1 : noOfRows);

                                                    }
                                                }
                                            }}>Next
                                            </button>


                                        </div> : null}


                                    {excelData && excelData.length > 0 ? <ExportToExcel posts={excelData}
                                                                                        dataTable={{
                                                                                            column: [{
                                                                                                Header: 'Site',
                                                                                                accessor: 'site',
                                                                                            }, {
                                                                                                Header: 'Device',
                                                                                                accessor: 'device',
                                                                                            }, ...columns],
                                                                                            data: excelData
                                                                                        }}
                                                                                        fileName={param !== null ? paramName : isCell ? "Cell Data" : "Battery Status"}
                                                                                        sheetName={"Sheet1"}/> : null}
                                    {param !== null || (showAqua && tableData.length > 0) ? <div className="img"
                                                                                                 style={{
                                                                                                     float: "right",
                                                                                                     marginRight: "50px",
                                                                                                     marginTop: "-50px",
                                                                                                     cursor: "pointer"
                                                                                                 }}><IconButton
                                        onClick={() => {
                                            setIsGraph(true)
                                        }}><img width={40} height={40} src={statisticsIcon}/></IconButton></div> : null}


                                    {/*
                                <table >
                                    <tbody><tr>
                                        <th>Cell ID</th>
                                        <th>Cell Name</th>
                                        <th>Cell Status</th>
                                        <th>Communication Status</th>
                                        <th>Voltage</th>
                                        <th>Temperature</th>
                                    </tr>
                                    {tableData && tableData.map((record, index) => {
                                        return <tr key={index} className={"resolved"}>
                                            <td>{record.id}</td>
                                            <td>{record.ctitle}</td>
                                            <td>{record.cellStatus}</td>
                                            <td>{record.comStatus}</td>

                                            <td>{record.value1}</td>
                                            <td>{record.value2}</td>
                                        </tr>
                                    })}
                                    </tbody></table>
*/}
                                </div>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CellStatsPopup;