import React, { useState } from 'react';
import {
    IOT_icon,
    UserNameIcon,
    screenBg,
    loginBg
} from "../Assets";
import {getDataFromUrl, requestList} from "../services/server";
import cons, { serverApi } from "../helper/Consts";
import { getCookie, setCookie } from "../helper/Cookies";
import { isUserAuthenticated } from "../helper/isLoggedIn";
import ChangePassword from "./ChangePassword";
import {Loader} from "./Common";

const Login = (props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showpassword, setShowpassword] = useState(false)
    const [changePassword, setChangePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, SetError] = useState('')

    const handleSubmit = () => {

        if(changePassword){
            if (username){
                setLoading(true);
                getDataFromUrl(serverApi.FORGOT_PASSWORD+username,handleClickonChangePassword);

            }
            else
                SetError("Enter User Name!!")

        }else {
            if (username && password) {
                var data = {
                    "userName": username,
                    "pwd": password
                };
                setLoading(true);
                requestList(serverApi.LOGIN, data, handleLogin);
                // service call & navigation goesr herei
                //console.log("username and password", username, password)
            } else {
                SetError("Incorrect Username/Password!!")
            }
        }


    };
    const handleLogin = (resp) => {
        console.log("login resp", resp);
        if (resp.httpStatusCode)
            SetError("Incorrect Username/Password!!")
        else {
            setCookie(cons.UDATA, JSON.stringify(resp));
            setCookie(cons.TOKEN_DATA, resp.token);
            if(resp.sites.Aqua||resp.sites.BMS){
                if(resp.sites.BMS)
                    setCookie(cons.ISAQUA,false);
                else
                    setCookie(cons.ISAQUA,true);
            }

            props.history.push("/dashboard")
        }
        setLoading(false)
    };

    const handleChange = (e, variable) => {
        SetError('')
        if (variable == "uname") {
            setUsername(e.target.value)
        } else {
            setPassword(e.target.value)
            if (e.target.value) {
                setShowpassword(true)
            }
        }

    }
    const renderRedirectToRoot = () => {
        const isAuthTokenValid = isUserAuthenticated();
        if (isAuthTokenValid) {
            props.history.push("/dashboard")
        }
    };

    const handleVisibility = () => {
        if (password) {
            setShowpassword(!showpassword)
        }

    };
    const handleClickonChangePassword = (resp) => {
        if(resp){
            alert(resp.message)
        }
        setLoading(false);
        setChangePassword(!changePassword);
    };
   const handleKeyPress = event => {
       console.log("login event", event);

       if (event.key === 'Enter') {
            handleSubmit()
        }
    };
    return (
        <div /*style={{ backgroundImage: "url(" + loginBg + ")", backgroundRepeat: 'repeat-y', float: "left", width: '100%', height: '100vh', backgroundSize: 'cover' }}*/>
            {renderRedirectToRoot()}
{/*
            {changePassword ? <ChangePassword closePopup={handleClickonChangePassword} loading={loading} /> : null}
*/}

                <div className="loginformcontainer">

                <div className="imgcontainer">
                    <img src={IOT_icon} alt="Logo" className="siteLogo" />
                </div>
                    {loading ? <Loader />:null}
                <div className="usernameblock">
                    <label htmlFor="uname"><b>Username</b></label>
                    <div className="inputblock">
                        <input type="text" value={username} onKeyPress={handleKeyPress} placeholder="Username" onChange={(e) => handleChange(e, "uname")} className="errorHighlight" />
                        <span className="symbol-input">
                            {/* <img className="usernameImage" src={UserNameIcon} /> */}
                            <i class="material-icons">
                                person</i>
                        </span>
                    </div>
                </div>
                {!changePassword ?
                    <div className="pswblock">
                    <label htmlFor="psw"><b>Password</b></label>
                    <div className="inputblock">
                        <input type={showpassword ? "password" : "text"} onKeyPress={handleKeyPress}  value={password} placeholder="**********" onChange={(e) => handleChange(e, "pass")} />
                        <span className="symbol-input" onClick={handleVisibility} style={{ cursor: 'pointer' }}>
                            <i class="material-icons" style={{ marginTop: 10 }}>{showpassword ? "visibility" : "visibility_off"}</i>
                        </span>
                    </div>
                </div>:null}
                <div className="errormsg">{error ? "Incorrect Username/Password!!" : null}</div>
                <button type="submit" onClick={handleSubmit}>{changePassword ?"Submit":"Sign In"}</button>
                <label style={{ textAlign: 'center', width: '100%' }} >
                    <span className="psw" onClick={() => {
                        console.log("cc", changePassword);
                        setChangePassword(!changePassword)
                    }}>{changePassword ?"Login?": "Forgot password?"}</span>
                </label>
            </div>

        </div>
    )
}

export default Login;