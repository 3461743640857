import React, {useState} from 'react';
import { Loader } from './Common'
import { temparatureIcon } from '../Assets';
import {getCookie, removeCookie} from "../helper/Cookies";
import cons, {serverApi} from "../helper/Consts";
import {getDashboardDetails, getDataFromUrl} from "../services/server";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
const AquaThanraDashboard = (props) => {


    return (
        <div>
        <div style={{width:"100%",textAlign:"center",fontSize:"20px",fontWeight:"bold" }}>
            <span >Aqua Thantra Is InProgress...</span>
        </div>
        </div>
    );
}

export default AquaThanraDashboard;