import React, {useEffect, useState} from 'react';
import { Loader } from './Common'
import { temparatureIcon } from '../Assets';
import CellStatsPopup from "./CellStatsPopup";
import CellGraphPopup from "./CellGraphPopup";
const CellDataPopup = (props) => {
    const { closePopup, dataResponse, loading ,site,device,iconsData,statasticsData,lastPacketsData,lastPacketReceivedTime,showAqua} = props;
    const [hover,setHover]=useState(false);
    const [Index,setIndex]=useState(0);
    const [data,setData]=useState(dataResponse);
    const [cellData,setCellData]=useState([]);
    const [cellName,setCellName]=useState("");

    const [cellStats, setCellStats] = useState(false);
    const [loadData,setLoadData]=useState(true);
    const [selectedCell,setSelectedCell]=useState(props.selectedCell);
    const [selectedLastPacket,setselectedLastPacket]=useState("Last Packet Time:"+lastPacketReceivedTime);

    useEffect(()=>{
        if(loadData){
            var cellData1=[];
            if(lastPacketsData){
                console.log("index",Index);
                var data1=lastPacketsData[Index].data.getDashboard;
                data1.forEach(prop=>{
                    if(prop.data&&prop.title!=="Bank Info"&&prop.title!=="Mfg. Details"){
                        cellData1.push(...prop.data)

                    }
                })
            }
            setCellData(cellData1);
            setLoadData(false);
            cellData1.forEach(prop=>{
                if(selectedCell==="All Cells"){
                    if(prop.title==="Total Cells"){
                        setData(prop.cellDetails)
                    }
                }else if(prop.title===selectedCell){
                    setData(prop.cellDetails)
                }
            });
            console.log("lastPacketsData",JSON.stringify(cellData));

        }

    });





    const temparatureStatus = (tempStat) => {
        let tempIcon = tempStat

      /*  if (tempStat == "hightemp") {
            tempIcon = temparatureIcon
        } else if (tempStat == "lowvoltage") {
            tempIcon = temparatureIcon
        } else if (tempStat == "discharge") {
            tempIcon = temparatureIcon
        }*/

        return <img src={tempIcon} />
    };
   function handleMouseIn() {
        setHover(true)
    }

    function handleMouseOut() {
        setHover(false)
    }
    const tooltipStyle = {
        display: hover ? 'block' : 'none',
        marginTop: "60px",
        textAlign:"right",
        paddingTop:"10px",
    };
    const handleClickonStats = () => {
        setCellStats(!cellStats)
    };

    return (
        <div className="popupBlockContainer">
            <div className="popupBlockMain">
                <div className="popupBlock">
                    <div className="popupHeader">
                        <select className="loation">
                            <option>{site}</option>
                          {/*  <option>Madhapur</option>
                            <option>Ameerpet</option>*/}
                        </select>
                        {!showAqua? <select className="id">
                            <option>{device}</option>
                          {/*  <option>Madhapur</option>
                            <option>Ameerpet</option>*/}
                        </select>:null}

                        <select className="id" value={selectedCell} onChange={(event)=>{
                            setSelectedCell(event.target.value);
                            var title=event.target.value;
                           setIndex(0);
                            setselectedLastPacket(lastPacketsData[0]);
                            setLoadData(true)

                            if(title==="All Cells"){
                                cellData.forEach(prop=>{
                                    if(prop.title==="Total Cells"){
                                        setData(prop.cellDetails)
                                    }
                                });
                            }else{
                                var has=false;
                                cellData.forEach(prop=>{
                                    if(prop.title===event.target.value){
                                        setData(prop.cellDetails);
                                        has=true
                                    }
                                });
                                if(!has){
                                    setData([])
                                }

                            }
                        }}>
                            <option>All Cells</option>
                            <option>Problem Cells</option>
                            <option>Communicating</option>
                            {Object.keys(iconsData).map((item, i) => (
                                 <option>{item}</option>
                            ))}
                          {/*  {iconsData?iconsData.map(prop=>{
                               return <option>{prop.title}</option>
                            }):null}*/}

                        </select>
                        <select className="id" value={selectedLastPacket} onChange={(event)=>{
                            lastPacketsData.forEach((prop,i)=>{
                                if("Last Packet Time: "+prop.lastPacketReceivedTime===event.target.value){
                                    setIndex(i);
                                    setselectedLastPacket(event.target.value);
                                    setLoadData(true)
                                }

                            });
                        }}>
                            {lastPacketsData.map((item, i) => (
                                <option>Last Packet Time: {item.lastPacketReceivedTime}</option>
                            ))}
                            {/*  {iconsData?iconsData.map(prop=>{
                               return <option>{prop.title}</option>
                            }):null}*/}

                        </select>

                        {/*
                        <div className="lastPacket1" >Last Packet Time
                            : {props.lastPacketReceivedTime}</div>
*/}

                        {/*
                        <select className="status">
                            <option>Select Status</option>
                            <option>Resolved</option>
                            <option>Pending</option>
                            <option>Medium</option>
                            <option>Low</option>
                            <option>High Risk</option>
                        </select>
*/}
                        <span className="popupClose" onClick={closePopup}><span class="material-icons">
                            close
</span></span>
                        <div className="popupTitleRight" style={{float:"right"}}>
                            {iconsData?Object.keys(iconsData).map((item, i) => (
                                <div className="popupicons" key={i} onClick={()=>{
                                    var has=false;
                                    setSelectedCell(item);

                                    cellData.forEach(prop=>{
                                        if(prop.title===item){
                                            setData(prop.cellDetails)
                                            has=true
                                        }
                                    });
                                    if(!has){
                                        setData([])

                                    }
                                }}>
                                    <div className="popupicons1">
                                        <div className="betteryStatusImage">{temparatureStatus(iconsData[item])}</div></div>
                                    <div className="betteryname1"  >{item}</div>
                                </div>
                            )):null}
                        </div>
                    </div>
                    <div className="popupMainBlock">
                        {loading ? <Loader /> : <div>
{/*
                            <div className="popupTitleBlock">

                                <div className="popupTitleRight" style={{float:"right"}}>
                                    {iconsData?Object.keys(iconsData).map((item, i) => (
                                        <div className="popupicons" key={i}>
                                            <div className="popupicons1">
                                            <div className="betteryStatusImage">{temparatureStatus(iconsData[item])}</div></div>
                                            <div className="betteryname1"  >{item}</div>
                                        </div>
                                    )):null}
                                </div>

                            </div>
*/}
                            <div className="popupTable" style={{maxHeight:450,overflowY:'auto',marginTop:"10px"}}>

                                {data && data.map((record, index) => {
                                    console.log("selectedCell",selectedCell)
                                    return <div className="betteryBlock" key={index} onClick={()=>{
                                        setCellName(record.ctitle)
                                        setCellStats(true)
                                    }}>
                                        <div className="betteryStatusImage">{temparatureStatus(record.icon)}</div>
                                        <div className="betteryInformation">
                                            <div className="betteryinfo" style={{color:selectedCell==="High Voltage"||selectedCell==="High Temperature"?"red":null,fontWeight:selectedCell==="High Voltage"||selectedCell==="High Temperature"?"bold":null}}>{record.value1}{/*<sup>0</sup>C*/}</div>
                                            <div className="betteryinfo" style={{color:selectedCell==="High Voltage"||selectedCell==="High Temperature"?"red":null,fontWeight:selectedCell==="High Voltage"||selectedCell==="High Temperature"?"bold":null}}>{record.value2}{/*<sup>0</sup>C*/}</div>

                                        </div>
                                        <div className="betteryname"  onMouseOver={()=>{
                                            handleMouseIn();

                                        }} onMouseOut={handleMouseOut}>{record.ctitle}</div>

{/*
                                        {index===Index?<div style={tooltipStyle}>{record.ctitle}<br/>{record.Value1}<br/>{record.Value2}</div>:null}
*/}
                                    </div>
                                })}
                            </div>

                        </div>}
                    </div>
                </div>
                {cellStats ? <CellGraphPopup  sitesId={site} deviceId={device} cellName={cellName} closePopup={handleClickonStats}/> : null }

            </div>
        </div>
    );
}

export default CellDataPopup;