import React, {useEffect, useState} from 'react';
import {Loader} from './Common'
import {temparatureIcon} from '../Assets';
import {getDataFromUrl} from "../services/server";
import {serverApi} from "../helper/Consts";
import {set} from "echarts/src/util/clazz";
import ReactTable from "react-table";
import IconButton from "@material-ui/core/IconButton";

const SitesDataPopup = (props) => {
    const {closePopup, data, loading, site, device, sites, name, isSite,info,isAqua} = props;
    console.log("sites data", data);
    const [hover, setHover] = useState(false);
    const [title, setTitle] = useState(name);
    const [isLoading, setIsLoading] = useState(true);
    const [activeItemIndex, setactiveItemIndex] = useState(props.activeItemIndex);
    const [sitesData, setSitesData] = useState(sites ? sites[props.activeItemIndex] : []);
    const [tableData, setTableData] = useState([]);

    console.log("data...", sites[props.activeItemIndex]);

    /*
        const { data, location, percentage } = dataResponse
    */
    const percentage = 50;
    const temparatureStatus = (tempStat) => {
        let tempIcon = tempStat

        /*  if (tempStat == "hightemp") {
              tempIcon = temparatureIcon
          } else if (tempStat == "lowvoltage") {
              tempIcon = temparatureIcon
          } else if (tempStat == "discharge") {
              tempIcon = temparatureIcon
          }*/

        return <img src={tempIcon}/>
    };

    function handleMouseIn() {
        setHover(true)
    }

    function handleMouseOut() {
        setHover(false)
    }

    const tooltipStyle = {
        display: hover ? 'block' : 'none',
        marginTop: "60px",
        textAlign: "right",
        paddingTop: "10px",
    };
    var columns = [];
   var status=sitesData.footer,statusColor="#000";
    if(status!==""){
        status=status.split(" ");
        status=status[1];
        if(status==="Normal"||status=="Charging"||status==="Communicating"){
            statusColor="#00b711"
        };
        if(status==="High"||status=="Discharging"||status==="Non Communicating")
            statusColor="#ff0000";
        if(status==="Low")
            statusColor="#fcaf31"
    }
    if (!isSite)
        columns = [
            {
                Header: 'Date Time',
                accessor: 'dateTime',
            },
            {
                Header: 'Site Name',
                accessor: 'name',
            },
            {
                Header: 'Serial No',
                accessor: 'serialNo',
            },
            {
                Header: 'Manufacturer',
                accessor: 'manufacturer',
            },
            {
                Header: 'First Used Date',
                accessor: 'firstDate',
            },
            {
                Header: 'Ah Capacity',
                accessor: 'ahCapacity',
            },
            {
                Header: 'Battery S.No',
                accessor: 'battrySrNo',
            },
            {
                Header: 'Design Voltage',
                accessor: 'designVoltage',
            },
            {
                Header: 'Battery Bank Type',
                accessor: 'batteryBankType',
            },
            {
                Header: 'Individual Cell Voltage',
                accessor: 'cellVoltage',
            },
            {
                Header: 'Status',
                accessor: 'status',
                style:{color:statusColor}
            },
            {
                Header: 'Location',
                accessor: 'location'
            }
        ];
    else
        columns = [

            {
                Header: 'Site Name',
                accessor: 'name',
            },
            {
                Header: 'Serial No',
                accessor: 'serialNo',
            },
            {
                Header: 'Manufacturer',
                accessor: 'manufacturer',
            },
            {
                Header: 'First Used Date',
                accessor: 'firstDate',
            },
            {
                Header: 'Ah Capacity',
                accessor: 'ahCapacity',
            },
            {
                Header: 'Battery S.No',
                accessor: 'battrySrNo',
            },
            {
                Header: 'Design Voltage',
                accessor: 'designVoltage',
            },
            {
                Header: 'Battery Bank Type',
                accessor: 'batteryBankType',
            },
            {
                Header: 'Individual Cell Voltage',
                accessor: 'cellVoltage',
            },

            {
                Header: 'Location',
                accessor: 'location'
            }
        ];
    if(isAqua){
        columns = [
            {
                Header: 'Site Name',
                accessor: 'name',
            },
            {
                Header: 'Serial No',
                accessor: 'serialNo',
            },

            {
                Header: 'Location',
                accessor: 'location'
            }
        ];

    }
    useEffect(()=>{
        var data1=[];

            if (!isSite) {
                if(isLoading&&sitesData){
                    if(sitesData.data){
                        data1 = sitesData.data.map((record, index) => {

                            var manufactureDetails = null, location = null, record1 = null;

                            data.forEach(prop => {
                                if (prop.id === record.siteId) {
                                    location = prop.location
                                    manufactureDetails = prop.manufactureDetails;
                                    record1 = prop;
                                }
                            });
                            return {
                                dateTime:record.dateTime,
                                name: record.siteId,
                                serialNo: record.serialNo,
                                manufacturer:manufactureDetails? manufactureDetails.manufacturerDetails:"",
                                firstDate:manufactureDetails? manufactureDetails.firstUsedDate:"",
                                ahCapacity:manufactureDetails? manufactureDetails.ahCapacity:"",
                                battrySrNo: manufactureDetails?manufactureDetails.batterySerialNumber:"",
                                designVoltage:manufactureDetails? manufactureDetails.designVoltage:"",
                                batteryBankType:manufactureDetails? manufactureDetails.typeOfBatteryBank:"",
                                cellVoltage:manufactureDetails? manufactureDetails.individualCellVoltage:"",
                                latitude:location ? location.latitude : "",
                                longitude:location ? location.longitude : "",
                                status:status,
                                location:(                       <IconButton  style={{color:"red",width:"60px",height:"30px"}}><i className="material-icons" >location_on</i>
                                    </IconButton>
                                )
                            }
                        });

                    }
                    setTableData(data1);
                    setIsLoading(false)
                }
                // setIsLoading(false)
            }else {
                if(isLoading&&data){
                    var info1=[];
                    info.forEach(prop=>{
                        if(title===prop.head){
                            info1=prop.sitesDet
                        }
                    });

                    data1 = info1.map((record, index) => {

                        var manufactureDetails = null, location = null, record1 = null;
                        data.forEach(prop => {
                            if (prop.id === record.siteId) {
                                location = prop.location
                                manufactureDetails = prop.manufactureDetails;
                                record1 = prop;
                            }
                        });
                        return {
                            name: record.siteId,
                            serialNo: record.serialNo,
                            manufacturer:manufactureDetails? manufactureDetails.manufacturerDetails:"",
                            firstDate:manufactureDetails? manufactureDetails.firstUsedDate:"",
                            ahCapacity:manufactureDetails? manufactureDetails.ahCapacity:"",
                            battrySrNo:manufactureDetails? manufactureDetails.batterySerialNumber:"",
                            designVoltage: manufactureDetails?manufactureDetails.designVoltage:"",
                            batteryBankType: manufactureDetails?manufactureDetails.typeOfBatteryBank:"",
                            cellVoltage: manufactureDetails?manufactureDetails.individualCellVoltage:"",
                            latitude:location ? location.latitude : "",
                            longitude:location ? location.longitude : "",
                            location:(
                                <IconButton  style={{color:"red",width:"60px",height:"30px"}}><i className="material-icons" >location_on</i>
                                </IconButton>
                            )
                        }
                    });
                    setTableData(data1);
                    setIsLoading(false)
                }
                //setIsLoading(false)
            }


    },);
   const onRowClick = (state, rowInfo, column, instance) => {

        return {
            onClick: e => {
                console.log("column",column);
                data.forEach(prop => {
                    if (prop.id === rowInfo.original.name) {
                        closePopup(prop)
                    }
                });

            }
        }
    };



    return (
        <div className="popupBlockContainer">
            <div className="popupBlockMain">
                <div className="popupBlock">
                    <div className="sitespopupHeader">
                        <div className="popupTitle">{title}</div>

                        <span className="popupClose" onClick={() => {
                            closePopup(null)
                        }}><span class="material-icons">
                            close
</span></span>
                        {!isSite? <select  value={title} onChange={(event) => {
                            console.log("select", event.target.value);
                            sites.forEach((o, i) => {
                                if (o.title=== event.target.value) {
                                    setactiveItemIndex(i);
                                    setSitesData(sites[i]);
                                    setTitle(o.title);
                                    setIsLoading(true);
                                }
                            });
                        }}>
                            {sites.length > 0 ? sites.map(prop => {
                                return (<option>{prop.title}</option>)
                            }) : null}
                        </select>:null}

                    </div>
                    <div className="popupMainBlock">
                        {loading ? <Loader/> : <div>
                            <div className="popupTable" >
                                {tableData ? <ReactTable
                                    loading={isLoading}
                                    data={tableData}
                                    columns={columns}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    showPaginationTop={false}
                                    getTrProps={onRowClick}
                                    style={{
                                        height: '100%',
                                        margin: 0
                                        // This will force the table body to overflow and scroll, since there is not enough room
                                    }}
                                    getTheadThProps={() => {
                                        return {
                                            style: {
                                                borderRight: null,
                                                whiteSpace: "pre-wrap",
                                                backgroundColor: "#009355",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                                //  wordWrap:"break-word",
                                                fontSize: '14px',
                                                opacity: 0.7,
                                                color: "#FFF",
                                                fontWeight: "bold",
                                                textAlign: "center"

                                            }
                                        }
                                    }}
                                    getTheadProps={() => {
                                        return {
                                            style: {
                                                borderRight: null,
                                            }
                                        }
                                    }}
                                    getTdProps={() => {
                                        return {
                                            style: {
                                                whiteSpace: "pre-wrap",
                                                wordWrap: "break-word",
                                                fontSize: '14px',
                                                opacity: 0.6,
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                borderRight: null,
                                                textAlign: "center",
                                                cursor:"pointer"
                                                //  backgroundColor:'green'

                                            }
                                        }
                                    }}


                                /> : null}

{/*
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Site Name</th>
                                        {!isSite ? <th>Serial No</th> : null}
                                        <th>Manufacturer</th>
                                        <th>First Used Date</th>
                                        <th>Ah Capacity</th>
                                        <th>Battery S.No</th>
                                        <th>Design Voltage</th>
                                        <th>Battery Bank Type</th>

                                        <th>Individual Cell Voltage</th>

                                        <th>Location</th>

                                          <th>Latitude</th>
                                            <th>Longitude</th>
                                    </tr>
                                    {!isSite ?
                                        sitesData.data.map((record, index) => {

                                            var manufactureDetails = null, location = null, record1 = null;
                                            data.forEach(prop => {
                                                if (prop.id === record.siteId) {
                                                    location = prop.location
                                                    manufactureDetails = prop.manufactureDetails;
                                                    record1 = prop;
                                                }
                                            });
                                            return <tr key={index}
                                                       onClick={() => {
                                                           closePopup(record1)
                                                       }}>
                                                <td>{record.siteId}</td>
                                                <td>{record.serialNo}</td>
                                                <td>{manufactureDetails ? manufactureDetails.manufacturerDetails : ""}</td>
                                                <td>{manufactureDetails ? manufactureDetails.firstUsedDate : ""}</td>
                                                <td>{manufactureDetails ? manufactureDetails.ahCapacity : ""}</td>
                                                <td>{manufactureDetails ? manufactureDetails.batterySerialNumber : ""}</td>
                                                <td>{manufactureDetails ? manufactureDetails.designVoltage : ""}</td>
                                                <td>{manufactureDetails ? manufactureDetails.typeOfBatteryBank : ""}</td>
                                                <td>{manufactureDetails ? manufactureDetails.individualCellVoltage : ""}</td>

                                                <td>{location ? location.latitude : ""}</td>
                                                <td>{location ? location.longitude : ""}</td>


                                                <td>{record.siteAddress?record.siteAddress.addrStr+", "+record.siteAddress.city+", "+record.siteAddress.state+", "+record.siteAddress.country:""}</td>


                                                  <td>{record.temparature}<sup>0</sup></td>
                                            <td>{record.presentCondition}</td>
                                            <td className={returnActioncls(record.action)}>{record.action}</td>
                                            </tr>
                                        }) : data.map((record, index) => {
                                            return <tr key={index}
                                                       onClick={() => {
                                                           closePopup(record)
                                                       }}>
                                                <td>{record.name}</td>
                                                <td>{record.manufactureDetails ? record.manufactureDetails.manufacturerDetails : ""}</td>
                                                <td>{record.manufactureDetails ? record.manufactureDetails.firstUsedDate : ""}</td>
                                                <td>{record.manufactureDetails ? record.manufactureDetails.ahCapacity : ""}</td>
                                                <td>{record.manufactureDetails ? record.manufactureDetails.batterySerialNumber : ""}</td>
                                                <td>{record.manufactureDetails ? record.manufactureDetails.designVoltage : ""}</td>
                                                <td>{record.manufactureDetails ? record.manufactureDetails.typeOfBatteryBank : ""}</td>
                                                <td>{record.manufactureDetails ? record.manufactureDetails.individualCellVoltage : ""}</td>

                                                <td>{record.location ? record.location.latitude : ""}</td>
                                                <td>{record.location ? record.location.longitude : ""}</td>


                                                <td>{record.siteAddress?record.siteAddress.addrStr+", "+record.siteAddress.city+", "+record.siteAddress.state+", "+record.siteAddress.country:""}</td>


                                                  <td>{record.temparature}<sup>0</sup></td>
                                            <td>{record.presentCondition}</td>
                                            <td className={returnActioncls(record.action)}>{record.action}</td>
                                            </tr>
                                        })}

                                    </tbody>
                                </table>
*/}
                            </div>

                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitesDataPopup;