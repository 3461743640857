import React, {useState} from 'react';
import {Loader} from './Common'
import {getDashboardDetails, getDataFromUrl, requestList} from "../services/server";
import cons, {serverApi} from "../helper/Consts";
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import {statisticsIcon} from "../Assets";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ExportToExcel from "./Common/ExportToExcel";
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import ReactEcharts from "./ReactECharts";
import Checkbox from '@material-ui/core/Checkbox';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {getCookie} from "../helper/Cookies";

const CellGraphPopup = (props) => {
    const {closePopup, dataResponse, loading, dashboardData, cellData, showAqua, sitesId,deviceId,cellName} = props;
    const [activeItemIndex, setActiveItemIndex] = useState(props.activeItemIndex);

    const data = dataResponse;
    const [cellsData, setcellsData] = useState(cellData ? cellData : []);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    var paramsData = [];
    var data1 = [];


    const getData = (pageNo, rows) => {
        var data = {
            "siteId": sitesId,
            "deviceId": deviceId,
            "cellOrBattery": "cell",
            "fromDate": fromDate !== "" ? fromDate + " 00:00" : "",
            "toDate": toDate !== "" ? toDate + " 24:00" : "",
            "page": 1,
            "rowsCount": 10
        };
        setIsLoading(true);
        requestList(serverApi.GET_STATASTICS, data, handleStats)
    };


    const handleStats = (resp) => {
        var list=[];
        if(resp.cellRes){
            resp.cellRes.forEach(o=>{
                o.cells.forEach(prop=>{
                    if(prop.cellName===cellName){
                        list.push(prop);
                    }
                })

            })
        }
        setTableData(list)
   };
    var xAxis = [], yAxis = [], yAxis1 = [], yAxis2 = [], yAxis3 = [], yAxis4 = [], xAxisName = "Date Time",
        yAxisName = "",
        yAxisName1 = "", yAxisName2 = "", yAxisName3 = "", yAxisName4 = "";
    var excelData = [], seriesData = [];
  var  columns = [
        {
            Header: 'Date',
            accessor: 'dateTime',
        },
        {
            Header: 'Name',
            accessor: 'cellName',
        },
        {
            Header: 'Voltage',
            accessor: 'voltageValue',
        },
        {
            Header: 'Temperature',
            accessor: 'temperatureValue',
        },
        {
            Header: 'Communication Status',
            accessor: 'commStatus',
        },
        {
            Header: 'Temperature Status',
            accessor: 'temperature',
        },
        {
            Header: 'Voltage Status',
            accessor: 'voltage',
        }
    ]

    excelData = tableData.map(prop => {
        if (!showAqua) {
            xAxis.push(prop.date + " " + prop.time);

                var val = prop.voltageValue.split(" ");
                yAxis.push(val[0]);
                yAxisName = val[1];

                var val = prop.temperatureValue.replace('°C', '');
                yAxis1.push(val);
                yAxisName1 = '°C';

            return {site: sitesId, device: deviceId,dateTime:prop.date+" "+prop.time, ...prop}

        }
    });


    return (
        <div className="popupBlockContainer">
            <div className="popupCellBlockMain">
                <div className="popupBlock">

                    <div className="popupHeader">
                        <select className="select_stats"
                                value={sitesId !== null ? sitesId : ""} onChange={(event) => {
                            console.log("select", event.target.value);
                        }}>
                            <option>{sitesId}</option>
                        </select>


                        <select className="select_stats" onChange={(event) => {
                        }}>
                            <option>{deviceId}</option>

                        </select>

                        <select className="select_stats" onChange={(event) => {
                        }}>
                            <option>{cellName}</option>

                        </select>
                        From Date :
                        <DayPickerInput
                            dayPickerProps={{
                                month: new Date(),
                                showWeekNumbers: true,
                                todayButton: 'Today',
                            }}
                            //  placeholder={'DD-MM-YYYY'}
                            //  formatDate={fromDate}

                            format={'YYYY-MM-DD'}
                            onDayChange={(dateObj) => {
                                setFromDate(moment(dateObj).format("YYYY-MM-DD"));
                                //  console.log("ddd",moment(dateObj).format("YYYY-MM-DD"))
                            }}
                            style={{marginTop: "-5px"}}
                        />
                        To Date :
                        <DayPickerInput
                            format={'YYYY-MM-DD'}
                            onDayChange={(dateObj) => {
                                setToDate(moment(dateObj).format("YYYY-MM-DD"));
                                //  console.log("ddd",moment(dateObj).format("YYYY-MM-DD"))
                            }}
                            dayPickerProps={{
                                month: new Date(),
                                showWeekNumbers: true,
                                todayButton: 'Today',
                            }}
                        />

                        <button style={{width: 80, height: 40, textAlign: "center", padding: 0}} type="submit"
                                onClick={() => {
                                    getData(pageNo, 10);

                                }}>Search
                        </button>

                        <span className="popupClose" onClick={closePopup}><span class="material-icons">
                            close
</span></span>

                    </div>
                    <div className="statspopupMainBlock">
                        <div style={{width:"95%" ,marginTop:"-15px"}}>
                        {excelData && excelData.length > 0 ? <ExportToExcel posts={excelData}
                                                                            dataTable={{
                                                                                column: [{
                                                                                    Header: 'Site',
                                                                                    accessor: 'site',
                                                                                }, {
                                                                                    Header: 'Device',
                                                                                    accessor: 'device',
                                                                                }, ...columns],
                                                                                data: excelData
                                                                            }}
                                                                            fileName={cellName}
                                                                            sheetName={"Sheet1"}/> : null}
                        </div>

                        <div className="statspopupTable" style={{  overflowY: "auto"}}>

                            <ReactEcharts
                                option={{
                                    color: ['#00ABDC', '#94BA33', '#003F66', '#e5323e'],
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            type: 'shadow'
                                        },
                                        formatter: '{b} <br/>{a} : {c} ' + yAxisName
                                    },
                                    legend: {
                                        data: ["Voltage"]
                                    },
                                    toolbox: {
                                        show: true,
                                        orient: 'vertical',
                                        left: 'right',
                                        top: 'center',
                                        feature: {
                                            mark: {show: true},
                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                            magicType: {
                                                show: true,
                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                            },
                                            restore: {show: true, title: "Restore"},
                                            saveAsImage: {show: true, title: "Save Image", name: "Voltage"},
                                        }
                                    },
                                    xAxis: [
                                        {
                                            type: 'category',
                                            axisTick: {show: false},
                                            data: xAxis,
                                            name: xAxisName
                                        }
                                    ],
                                    yAxis: [
                                        {
                                            type: 'value', name: yAxisName
                                        }
                                    ],
                                    series: [
                                        {
                                            name: "Voltage",
                                            type: 'bar',
                                            barGap: 0,
                                            //  label: labelOption,
                                            data: yAxis
                                        }
                                    ]
                                }}
                                showLoading={false}
                                style={{height: '400px', width: '100%'}}/>
                            <ReactEcharts
                                option={{
                                    color: ['#003F66', '#94BA33', '#003F66', '#e5323e'],
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            type: 'shadow'
                                        },
                                        formatter: '{b} <br/>{a} : {c} ' + yAxisName1
                                    },
                                    legend: {
                                        data: ["Temperature"]
                                    },
                                    toolbox: {
                                        show: true,
                                        orient: 'vertical',
                                        left: 'right',
                                        top: 'center',
                                        feature: {
                                            mark: {show: true},
                                            dataView: {show: false, readOnly: false, title: "Data View"},
                                            magicType: {
                                                show: true,
                                                type: ['line', 'bar', 'stack', 'tiled'],
                                                title: {line: "Line", bar: "Bar", stack: "Stack", tiled: "tiled"}
                                            },
                                            restore: {show: true, title: "Restore"},
                                            saveAsImage: {show: true, title: "Save Image", name: "Temperature"},
                                        }
                                    },
                                    xAxis: [
                                        {
                                            type: 'category',
                                            axisTick: {show: false},
                                            data: xAxis,
                                            name: xAxisName
                                        }
                                    ],
                                    yAxis: [
                                        {
                                            type: 'value', name: yAxisName1
                                        }
                                    ],
                                    series: [
                                        {
                                            name: "Temperature",
                                            type: 'bar',
                                            barGap: 0,
                                            //  label: labelOption,
                                            data: yAxis1
                                        }
                                    ]
                                }}
                                showLoading={false}
                                style={{height: '400px', width: '100%'}}/>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};
export default CellGraphPopup;
