import React, {useEffect, useState} from 'react';
import {Loader} from './Common'
import {getDashboardDetails, getDataFromUrl, requestList} from "../services/server";
import cons, {serverApi} from "../helper/Consts";
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import {statisticsIcon} from "../Assets";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ExportToExcel from "./Common/ExportToExcel";
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import ReactEcharts from "./ReactECharts";
import {getCookie} from "../helper/Cookies";

const Cpanel = (props) => {

    const {closePopup} = props;
    const [isAdd, setIsadd] = useState(false);
    const [sitesData, setSitesData] = useState(props.sitesData ? props.sitesData : []);
    const [sitesId, setSitesId] = useState(props.siteId ? props.siteId : null);
    const [deviceId, setDeviceId] = useState(props.device ? props.device : null);
    const [deviceData, setDeviceData] = useState(props.deviceData ? props.deviceData : []);

    useEffect(()=>{
        getDataFromUrl(serverApi.GET_CPANEL_DATA,handleData)
    },[]);
    const handleData=(resp)=>{
        console.log("cpanel",resp);
    }
    const handleDeviceData = (resp) => {
        if (resp) {
            setDeviceData(resp);
            if(resp.length>0)
                setDeviceId(resp[0]);
        }
    };
    var userData = JSON.parse(getCookie(cons.UDATA));
    var userId = userData.id;
    return (
        <div className="popupBlockContainer">
            <div className="popupBlockMain">
                <div className="popupBlock">
                    <div className="popupHeader">
                        {isAdd?                            <IconButton style={{
                            width: "50px",
                            height: "40px",
                            float: "left",
                            padding: 0,
                            margin: 0,
                            fontWeight:"bold",

                            cursor: "pointer",color:"#4caf50"

                        }} onClick={() => {
                            setIsadd(false)
                        }}>   <i className="material-icons"
                        >arrow_back</i>
                        </IconButton>:null}
                        <div className="popupTitle">Cpanel</div>


                        <span className="popupClose" onClick={closePopup}><span class="material-icons">
                            close
</span></span>
                        {!isAdd?  <button style={{
                            width: 80,
                            height: 40,
                            textAlign: "center",
                            padding: 0,
                            float: "right",
                            marginRight: "10px",
                            marginTop: "-5px"
                        }} type="submit" onClick={() => {
                            setIsadd(true)
                        }}>ADD
                        </button>:    null
}

                            </div>
                    <div className="statspopupMainBlock">
                        {isAdd ? <div style={{width: "100%"}}>

                            <div className="inputTextblock">
                                <label htmlFor="uname"><b>Site</b></label>
                                <div style={{ width: "100%",
                                    display: "block",
                                    border: "none",
                                    height: "30px"}}>
                                    <select className="select_cpanel" placeholder={"Select Site"} value={sitesId !== null ? sitesId.name : ""} onChange={(event) => {
                                        console.log("select", event.target.value);
                                        sitesData.forEach(o => {
                                            if (o.name + "" === event.target.value) {
                                                setSitesId(o);

                                                getDataFromUrl(serverApi.GET_DEVICES_BY_SITE_ID + o.id+"&userId="+userId, handleDeviceData);

                                            }
                                        });
                                    }}>
                                        {sitesData.length > 0 ? sitesData.map(prop => {
                                            return (<option>{prop.name}</option>)
                                        }) : null}
                                    </select>
                                </div>
                            </div>
                            <div className="inputTextblock">
                                <label htmlFor="uname"><b>Device</b></label>
                                <div style={{ width: "100%",
                                    display: "block",
                                    border: "none",
                                    height: "30px"}}>
                                    <select className="select_cpanel" placeholder={"Select Device"} onChange={(event) => {
                                        deviceData.forEach(o => {
                                            if (o.serialNo + "" === event.target.value) {
                                                setDeviceId(o)

                                            }
                                        });
                                    }}>
                                        {deviceData.length > 0 ? deviceData.map(prop => {
                                            return (<option>{prop.serialNo}</option>)
                                        }) : null}

                                    </select>
                                </div>
                            </div>



                                <div className="inputTextblock">
                                    <label htmlFor="uname"><b>First Used Date</b></label>
                                    <div className="inputblock1">
                                        <DayPickerInput
                                            dayPickerProps={{
                                                month: new Date(),
                                                showWeekNumbers: true,
                                                todayButton: 'Today',
                                            }}
                                            //  placeholder={'DD-MM-YYYY'}
                                            //  formatDate={fromDate}

                                            format={'YYYY-MM-DD'}
                                            onDayChange={(dateObj) => {
                                                // setFromDate(moment(dateObj).format("YYYY-MM-DD"));
                                            }}
                                            style={{height: "30px", paddingTop: "5px"}}
                                        />
                                    </div>
                                </div>
                            <div className="inputTextblock">
                                <label htmlFor="uname"><b>Battery Serial Number</b></label>
                                <div className="inputblock1">
                                    <input type="text"
                                           placeholder="Serial Number" onChange={(e) => {
                                    }}
                                           className="errorHighlight"/>
                                </div>
                            </div>
                            <div className="inputTextblock">
                                <label htmlFor="uname"><b>Type Of Battery Bank</b></label>
                                <div className="inputblock1">
                                    <input type="text"
                                           placeholder="Type Of Battery Bank" onChange={(e) => {
                                    }}
                                           className="errorHighlight"/>
                                </div>
                            </div>

                            <div className="inputTextblock">
                                    <label htmlFor="uname"><b>Ah Capacity</b></label>
                                    <div className="inputblock1">
                                        <input type="text"
                                               placeholder="Ah Capacity" onChange={(e) => {
                                        }}
                                               className="errorHighlight"/>
                                    </div>
                                </div>




                                <div className="inputTextblock">
                                    <label htmlFor="uname"><b>Manifacture Name</b></label>
                                    <div className="inputblock1">
                                        <input type="text"
                                               placeholder="Manifacture Name" onChange={(e) => {
                                        }}
                                               className="errorHighlight"/>
                                    </div>
                                </div>
                                <div className="inputTextblock">
                                    <label htmlFor="uname"><b>Design Voltage</b></label>
                                    <div className="inputblock1">
                                        <input type="text"
                                               placeholder="Design Voltage" onChange={(e) => {
                                        }}
                                               className="errorHighlight"/>
                                    </div>
                                </div>
                                <div className="inputTextblock">
                                    <label htmlFor="uname"><b>Individual Cell Voltage</b></label>
                                    <div className="inputblock1">
                                        <input type="text"
                                               placeholder="Individual Cell Voltage" onChange={(e) => {
                                        }}
                                               className="errorHighlight"/>
                                    </div>
                                </div>


                            <div style={{width:"50%" ,float:"right"}}>


                            <button type="submit" style={{
                                width: 100,
                                height: 40,
                                textAlign: "center",
                                padding: 0,
                            }} >Save</button>
                            </div>
                        </div> : <div></div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cpanel;