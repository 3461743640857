import React, { useState } from 'react';
import { Popper, ClickAwayListener, Grow, Paper, Menu, MenuItem } from '@material-ui/core';
import {getCookie, removeCookies, setCookie} from "../../helper/Cookies";
import logo from "../../Assets/vajra-logo_png.png"
import NotificationsPopup from "../NotificationsPopup";
import cons from "../../helper/Consts";
const Header = (props) => {
    const {openCpanel}=props;
    const notifications = props.notifications;
    const [anchorEl, open] = React.useState(null);
    const [notifStats, setNotifStats] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAqua, setIsAqua] = useState(getCookie(cons.ISAQUA)==="true"?true:false);

    const handleClickonnotifications = () => {
        // Once after service call we need to fetch the stop the loader
        setLoading(false);
        setNotifStats(!notifStats)
    };
    var name="VJ";
    var userData=getCookie(cons.UDATA)?JSON.parse(getCookie(cons.UDATA)):null;
    if(getCookie(cons.UDATA)){
        name=userData.userName;
        name=name[0]+name[1];
        name=name.toLocaleUpperCase()
    }
    const handleClick = event => {
        open(event.currentTarget);
    };

    const handleClose = () => {
        open(null);
    };
    return (<header>
        <div className="headerBlock">
            <div className="container-fluid">
                <div className="logo_block"><img src={logo} width={90} height={40} /><span style={{ marginLeft: "15px", fontWeight: "bold" }}></span></div>
                <div className="header_mid">{isAqua?"AQUA TANTRA":"BATTERY MANAGEMENT SYSTEM"}</div>
                <div className="right_block header_right">
                    <div className="site_selection">
                        <select class="site_selection_options" value={isAqua?"AQUA TANTRA":"BATTERY MANAGEMENT SYSTEM"} onChange={(event)=>{
                            if(event.target.value==="AQUA TANTRA"){
                                setIsAqua(true);
                                props.redirectToAqua(true);

                            }else {
                                setIsAqua(false);
                                props.redirectToAqua(false)
                            }
                        }}>
                            {userData&&userData.sites.BMS? <option>BATTERY MANAGEMENT SYSTEM</option>:null}
                            {userData&&userData.sites.Aqua? <option>AQUA TANTRA</option>:null}
                        </select>
                    </div>
                    <div className="notificationIcon">
                        <i className="fa fa-bell" aria-hidden="true" style={{marginLeft:"20px", cursor: "pointer",color:notifications.length>0?"red":"#FFFFFF" }} onClick={() => {
                            if (notifications) {
                                handleClickonnotifications();
                            }
                        }} />
                    </div>
                    <div className="userProfile" onClick={handleClick}>
                        <span>{name}</span>
                    </div>
                    <Menu
                        id="Menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className="menuDropdown"
                    >
                        <MenuItem onClick={() => {
                            open(null);

                        }}>My Profile</MenuItem>
                        <MenuItem onClick={() => {
                            open(null);
                            props.handleClickonChangePassword();

                        }}>Change Password</MenuItem>
                       {/* <MenuItem onClick={() => {
                            open(null);
                            props.openCpanel();

                        }}>Cpanel</MenuItem>
                        <MenuItem onClick={() => {
                            open(null);

                        }}>Configuration</MenuItem>
*/}
                        <MenuItem onClick={() => {
                            open(null);
                            removeCookies();
                            props.redirect();
                            //  window.location.reload();

                        }}>Logout</MenuItem>
                    </Menu>
                </div>

            </div>

        </div>
        {notifStats ? <NotificationsPopup dataResponse={notifications.length > 0 ? notifications : []} closePopup={handleClickonnotifications} loading={loading} /> : null}

    </header>);
}

export default Header;