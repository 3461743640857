import React, {useState} from 'react';
import {Loader} from './Common'
import {getDataFromUrl, requestList} from "../services/server";
import cons, {serverApi} from "../helper/Consts";
import {getCookie} from "../helper/Cookies";

const ChangePassword = (props) => {
    const {closePopup, dataResponse, loading, site, device} = props;
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmPassword] = useState('')

    const handleResp = (resp) => {
        if(resp.status===true){
            closePopup()
        }
        alert(resp.message);


        /*  var txt;
          if (r === true) {
          } else {
              var r = alert(resp.message);
          }*/
    };
    const handleKeyPress = event => {
        console.log("login event", event);

        if (event.key === 'Enter') {
            handleSubmit()
        }
    };
    const handleSubmit = () => {
            if (username && password&&confirmpassword) {
                if(password===confirmpassword){
                    var userData = JSON.parse(getCookie(cons.UDATA));
                    var data={
                        username:userData.userName,
                        oldPwd:username,
                        newPwd:password
                    }

                    requestList(serverApi.CHANGE_PASSWORD+userData.userName+"/"+username+"/"+password,{},handleResp);

                }else {
                    alert("Password and confirm password should be same..!!")
                }

            } else {
                alert("Please enter all fields..!!")
            }
    };


    return (
        <div className="popupBlockContainer">
            <div className="changePasswordBlockContainer">
                <div className="popupBlock">
                    <div className="popupHeader" onClick={closePopup}>
                        <div style={{color: "red", marginLeft: "10px", fontSize: "18px"}}>Change Password

                            <span className="popupClose"><span className="material-icons">
                            close
</span></span>

                        </div>
                    </div>

                    <div className="popupPasswordMainBlock">
                        {loading ? <Loader/> : <div>

                            <div className="usernameblock">
                                <label htmlFor="uname"><b>Old Password</b></label>
                                <div className="inputblock">
                                    <input type="text" value={username}
                                           placeholder="" onChange={(e) => setUsername(e.target.value)}
                                           className="errorHighlight"/>
                                    <span className="symbol-input">
                            {/* <img className="usernameImage" src={UserNameIcon} /> */}
                        </span>
                                </div>
                            </div>
                            <div className="usernameblock">
                                <label htmlFor="psw"><b>Password</b></label>
                                <div className="inputblock">
                                    <input type={ "password"} onKeyPress={handleKeyPress}
                                           value={password} placeholder="**********"
                                           onChange={(e) => setPassword(e.target.value)}/>
                                    <span className="symbol-input"
                                          style={{cursor: 'pointer'}}>
                        </span>
                                </div>
                            </div>
                            <div className="usernameblock">
                                <label htmlFor="psw"><b>Confirm Password</b></label>
                                <div className="inputblock">
                                    <input type={ "password"} onKeyPress={handleKeyPress}
                                           value={confirmpassword} placeholder="**********"
                                           onChange={(e) => setConfirmPassword(e.target.value)}/>
                                    <span className="symbol-input"
                                          style={{cursor: 'pointer'}}>
                        </span>
                                </div>
                            </div>


                        </div>}
                        <button  type="submit"
                                onClick={handleSubmit}>Submit
                        </button>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default ChangePassword;